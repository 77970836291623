import React, { useState, useEffect } from "react";
import { Button, Card, message, Select, DatePicker, Modal } from "antd";
import {
  useGetLatestActivitiesQuery,
  useNotifyUsersMutation,
} from "./SendNotificationApiSlice";
import CompetitionDropdown from "../../quiz/competitionDropdown";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import CompetitionFormForClone from "../../quiz/CompetitionForms/CompetitionFormForClone";
import dayjs from "dayjs";
import CompetitionSelect from "../../quiz/competitionSelect";
import LifeSkill from "../../quiz/LifeSkill";

const { Option } = Select;

interface LatestActivity {
  id: string;
  title: string;
  targetGradeLevels: number[];
  meta: {
    type: string;
  };
  info: {
    questionCount: number;
  };
  questionIds: any[];
  description: string;
  startDate: string;
  // ... other fields
}

const SendNotification: React.FC = () => {
  const [filters, setFilters] = useState({
    competitionId: "",
    grade: undefined as number | undefined, // Exclude grade if undefined
    date: undefined as string | undefined,
  });
  const [expandedActivityId, setExpandedActivityId] = useState<string | null>(
    null
  );
  const [showCloneForm, setShowCloneForm] = useState(false);
  const [activityToClone, setActivityToClone] = useState<any>(
    null
  );

  // const {
  //   data: latestActivities,
  //   isLoading,
  //   refetch,
  // } = useGetLatestActivitiesQuery(filters);

  const {
    data: latestActivities,
    isLoading,
    refetch,
  } = useGetLatestActivitiesQuery(
    filters.grade
      ? filters 
      : { competitionId: filters.competitionId, date: filters.date }, // Exclude grade if undefined
    { skip: !filters.date } 
  );
 
  const [sendNotification, { isLoading: notificationIsLoading }] =
    useNotifyUsersMutation();

  useEffect(() => {
    if (filters.date) {
      refetch();
    }
  }, [filters, refetch]);

  const handleSendNotification = async (activityId: string) => {
    try {
      await sendNotification(activityId);
      message.success("Notification sent successfully!");
    } catch (error) {
      message.error("Failed to send notification. Please try again.");
    }
  };

  const handleFilterChange = (key: string, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleDateChange = (value: dayjs.Dayjs | null) => {
    handleFilterChange("date", value ? value.format("YYYY-MM-DD") : undefined);
  };

  const handleCloneActivity = (activity: any) => {
    setActivityToClone(activity);
    setShowCloneForm(true);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading latest activities...
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-8 text-[#4a4a4a]">
        Recent Activities
      </h2>

      {/* Filters */}
      <div className="mb-6 flex gap-4">
        <DatePicker
          placeholder="Select Date"
          onChange={handleDateChange}
          value={filters.date ? dayjs(filters.date) : null}
          style={{ width: 200 }}
        />
        <Select
          placeholder="Select Grade"
          onChange={(value: number) => handleFilterChange("grade", value)}
          value={filters.grade}
          allowClear
          style={{ width: 200 }}
          disabled={!filters.date}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <Option key={i + 1} value={i + 1}>
              {i + 1}
            </Option>
          ))}
        </Select>
        {filters.date  && (
          <CompetitionSelect
            setCompetitionId={(id) => handleFilterChange("competitionId", id)}
          />
        )}
      </div>

      {/* Activity Cards */}
      <div className="grid grid-cols-1 gap-6">
        {latestActivities?.map((activity: any) => (
          <Card
            key={activity.id}
            className="bg-white rounded-xl shadow-sm flex flex-col justify-between transition-all hover:shadow-md"
          >
            <div>
              <h3 className="text-xl font-semibold text-[#333]">
                {activity.title}
              </h3>
              <div className="text-gray-500 text-sm mt-2">
                <div>
                  <strong>Quiz ID:</strong> {activity.id}
                </div>
                {activity.targetGradeLevels.length > 0 && (
                  <div>
                    <strong>Target Grades:</strong>{" "}
                    {activity.targetGradeLevels.join(", ")}
                  </div>
                )}
                <div>
                  <strong>Type:</strong> {activity.meta.type}
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-end w-full space-x-2">
              <Button
                onClick={() => handleSendNotification(activity.id)}
                disabled={notificationIsLoading || !activity.id}
              >
                {notificationIsLoading ? "Notifying..." : "Notify user"}
              </Button>

              <Button
                onClick={() =>
                  setExpandedActivityId(
                    expandedActivityId === activity.id ? null : activity.id
                  )
                }
              >
                {expandedActivityId === activity.id
                  ? "Hide Questions"
                  : "Show Questions"}
              </Button>

              <Button onClick={() => handleCloneActivity(activity)}>
                Clone
              </Button>
            </div>

            {expandedActivityId === activity.id && (
              <div className="mt-4">
                <h4 className="font-semibold text-gray-600">Questions:</h4>
                {activity.questionIds.map((question:any, index:number) => (
                  <McqViewer key={index} question={question} />
                ))}
              </div>
            )}
          </Card>
        ))}
      </div>

      {/* Clone Form Modal */}
      {/* {activityToClone?.type === "skill-module" ? <Modal
        width={"90%"}
        className=" h-[90%]"
        title="Clone Activity"
        visible={showCloneForm}
        onCancel={() => setShowCloneForm(false)}
        footer={null}
      >
        {activityToClone && (
          <LifeSkill
            initialValues={activityToClone}
            onClose={() => setShowCloneForm(false)}
          />
        )}
      </Modal> : <Modal
        width={"90%"}
        className=" h-[90%]"
        title="Clone Activity"
        visible={showCloneForm}
        onCancel={() => setShowCloneForm(false)}
        footer={null}
      >
        {activityToClone && (
          <CompetitionFormForClone
            initialValues={activityToClone}
            onClose={() => setShowCloneForm(false)}
          />
        )}
      </Modal>  } */}
      
      <Modal
        width={"90%"}
        className=" h-[90%]"
        title="Clone Activity"
        visible={showCloneForm}
        onCancel={() => setShowCloneForm(false)}
        footer={null}
      >
        {activityToClone && (
          <CompetitionFormForClone
            initialValues={activityToClone}
            onClose={() => setShowCloneForm(false)}
          />
        )}
      </Modal>

    </div>
  );
};

export default SendNotification;
