import React, { ReactNode } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

interface CardWrapperProps {
  title: string;
  description: string;
  children: ReactNode;
  footer?: ReactNode;
  showSocial?: boolean;
  backButtonHref?: string;
  backButtonLabel?: string;
  height?: string; // Add height prop
}

export function CardWrapper({
  title,
  description,
  children,
  showSocial,
  backButtonHref,
  backButtonLabel,
  height = "900px", // Set default height to 900px
}: CardWrapperProps) {
  return (
    <Card className={`max-h-[${height}] overflow-y-scroll`}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>{children}</CardContent>
      {/* {showSocial && (
        <CardFooter className="flex justify-between">
          <Social />
        </CardFooter>
      )}
      <CardFooter>
        <LinkButton href={backButtonHref} label={backButtonLabel} />{" "}
      </CardFooter> */}
    </Card>
  );
}
