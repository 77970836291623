import { apiSlice } from "../../app/api/apiSlice";
import { CaseData } from "../../components/dash-components/CaseReviewer";
import { Statement, StatementCount } from "./types";

const casesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopicwiseStatementsCount: builder.query<StatementCount[], void>({
      query: () => "/cases/meta",
      transformResponse: (response: StatementCount[]) => {
        const transformed = response.map((item) => {
          item.gradeLevel = item.gradeLevel || 0;
          item.topic = item.topic || "N/A";
          item.subtopic = item.subtopic || "N/A";
          item.count = item.count || 0;
          return item;
        });
        return transformed;
      },
    }),
    // getStatementList: builder.query<
    //   {
    //     total: number;
    //     data: Statement[];
    //   },
    //   { skip?: number; limit?: number; topicId?: string }
    // >({
    //   query: (queryParams) => ({
    //     url: "/cases",
    //     method: "GET",
    //     params: queryParams,
    //   }),
    // }),
    getStatementList: builder.query<
      { total:number, data: Statement[] },
      { skip?: number; limit?: number; topicId?: string | null; subtopicId?: string | null; classId?: string | null }
    >({
      query: (queryParams) => ({
        url: "/cases",
        method: "GET",
        params: queryParams,
      }),
      // Add this to handle pagination properly
      transformResponse: (response: { total: number; data: Statement[] }) => ({
        total: response.total,
        data: response.data,
      }),
    }),

    updateCaseStatement: builder.mutation({
      query: ({ id, data }) => ({
        url: `/cases/${id}/statement`,
        method: "PUT",
        body: { data: data },
      }),
    }),
    createCase: builder.mutation<any, any>({
      query: (data) => ({
        url: `/cases?extIdCheck=true&grade=${data.grade}`,
        method: "POST",
        body: data,
      }),
    }),
    updateCase: builder.mutation<any, any>({
      query: (data) => ({
        url: `/cases/${data.id}?grade=${data.grade}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetStatementListQuery,
  useUpdateCaseStatementMutation,
  useGetTopicwiseStatementsCountQuery,
  useCreateCaseMutation,
  useUpdateCaseMutation,
} = casesApi;

export default casesApi;
