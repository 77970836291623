import React from "react";

import ProfessionCompetitionForm from "./ProfessionCompetition";
import FoundationalForm from "./FoundationalForm";
import ShowSubjectByGrade from "./ShowSubjectByGrade";
import NotifyUsersDialog from "./NotifyUsersDialog";
import ActivityForm from "./AcademicWorksheetForm";
import AcademicWorksheetForm from "./AcademicWorksheetForm";
import FlnForm from "./FlnForm";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";



const AllQuizes = () => {
  const navigate = useNavigate();
  const navigateToCompetitionForm = () => {
    navigate("competition");
  };
  const navigateToAcademicsForm = () => {
    navigate("academics");
  };
  const navigateToProfessionForm = () => {
    navigate("profession");
  };
  const navigateToflnForm = () => {
    navigate("fln");
  };
  const navigateToLifeSkillForm = () => {
    navigate("life-skill");
  };

  return (
    <div className="flex flex-col items-start gap-2 ml-4 mt-4">
      <Button onClick={navigateToCompetitionForm}>Competition Form</Button>
      {/* <CompetitionForm  /> */}
      <Button onClick={navigateToProfessionForm}>Profession  Form</Button>
      {/* <ProfessionCompetitionForm  /> */}
      {/* <FoundationalForm /> */}
      {/* <ShowSubjectByGrade grade={10} /> */}
      <NotifyUsersDialog />
      <Button onClick={navigateToAcademicsForm}>Create academic foundational worksheet</Button>
      {/* <AcademicWorksheetForm /> */}
      <Button onClick={navigateToflnForm}>Create FLN</Button>
      {/* <FlnForm/> */}
      <Button onClick={navigateToLifeSkillForm}>Create Life Skill</Button>
    </div>
  );
};

export default AllQuizes;









// import React, { useState } from "react";
// import CompetitionForm from "./CompetitionForm";
// import ProfessionCompetitionForm from "./ProfessionCompetition";
// import NotifyUsersDialog from "./NotifyUsersDialog";
// import AcademicWorksheetForm from "./AcademicWorksheetForm";
// import { IMtfQuestion, MatchComponentType } from "../components/dash-components/Mtf/types";
// import {MtfEditor} from "../components/dash-components/Mtf/MtfEditor";
// import MatchTheFollowingViewer from "../components/dash-components/Mtf/MtfViewer";
// import { useUploadImageMutation } from "../app/api/apiSlice";

// const dummyMatchQuestion: IMtfQuestion = {
//       en_question: [
//         {
//           type: MatchComponentType.TEXT,
//           value: "Match the following:",
//         },
//       ],
//       pairs: [
//         {
//           question: {
//             type: MatchComponentType.TEXT,
//             value: "Apple",
//           },
//           answer: {
//             type: MatchComponentType.TEXT,
//             value: "Fruit",
//           },
//         },
//         {
//           question: {
//             type: MatchComponentType.IMAGE,
//             value: "https://example.com/image1.jpg",
//           },
//           answer: {
//             type: MatchComponentType.TEXT,
//             value: "Image 1 Answer",
//           },
//         },
        
//   ],
//   id: "1",
// };
    
// const dummyMatchQuestions: IMtfQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Match the following:",
//     },
//   ],
//   pairs: [
//     {
//       question: {
//         type: MatchComponentType.TEXT,
//         value: "Apple",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Fruit",
//       },
//     },
//     {
//       question: {
//         type: MatchComponentType.IMAGE,
//         value: "https://example.com/image1.jpg",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Image 1 Answer",
//       },
//     },
    
// ],
// id: "2",
// };

// const AllModals = () => {
//   const [questions, setQuestions] = useState<IMtfQuestion[]>([dummyMatchQuestion, dummyMatchQuestions]); // State for questions
//   const [editing, setEditing] = useState(false);
//   const [editingQuestion, setEditingQuestion] = useState<IMtfQuestion | undefined>();
//   const [uploadAsset] = useUploadImageMutation();

//   const handleEdit = (question: IMtfQuestion) => {
//     handleSave(question);
//     setEditing(true);
//     setEditingQuestion(question);
//   };

//   const handleSave = (updatedQuestion: IMtfQuestion) => {
//     console.log("Saving updated question:", updatedQuestion); // Log the updated question
//     setQuestions((prev) =>
//       prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
//     ); // Update questions state
//     setEditing(false);
//   };

//   const handleCloseEditor = () => {
//     setEditing(false);
//   };

//   const uploadAudio = async (formData: FormData) => {
//     const result = await uploadAsset({
//       formData,
//       prefix: "mtf-audio",
//     }).unwrap();
//     return result.url;
//   };
// console.log(questions)
//   const uploadImage = async (formData: FormData) => {
//     const result: any = await uploadAsset({
//       formData,
//       prefix: "mtf-image",
//     }).unwrap();
//     return result.url;
//   };

//   return (
//     <div className="flex flex-col items-start gap-2 ml-4 mt-4">
//       <CompetitionForm />
//       <ProfessionCompetitionForm />
//       <NotifyUsersDialog />
//       <AcademicWorksheetForm />

//       {editing ? (
//         <MtfEditor
//           uploadImage={uploadImage}
//           uploadAudio={uploadAudio}
//           onSave={handleSave}
//           data={editingQuestion} // Start with empty data for new questions
//         />
//       ) : (
//         questions.map((question, index) => (
//           <MatchTheFollowingViewer
//             key={index}
//             question={question}
//             onEdit={() => handleEdit(question)}
//           />
//         ))
//       )}
//     </div>
//   );
// };

// export default AllModals;






// import React, { useState } from "react";
// import CompetitionForm from "./CompetitionForm";
// import ProfessionCompetitionForm from "./ProfessionCompetition";
// import NotifyUsersDialog from "./NotifyUsersDialog";
// import AcademicWorksheetForm from "./AcademicWorksheetForm";
// import { IRearrangeQuestion, RearrangeComponentType,MatchComponentType } from "../components/dash-components/Rearrange/types";
// import { RearrangeEditor } from "../components/dash-components/Rearrange/RearrangeEditor";
// import RearrangeViewer from "../components/dash-components/Rearrange/RearrangeViewer";
// import { useUploadImageMutation } from "../app/api/apiSlice";


// // Dummy Rearrange Question Data
// const dummyRearrangeQuestion: IRearrangeQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Rearrange the following items in correct order:",
//     },
//   ],
//   components: [
//     {
//       key: 1,
//       type: MatchComponentType.TEXT,
//       value: "First item",
//     },
//     {
//       key: 2,
//       type: MatchComponentType.TEXT,
//       value: "Second item",
//     },
//     {
//       key: 3,
//       type: MatchComponentType.TEXT,
//       value: "Third item",
//     },
//     {
//       key: 4,
//       type: MatchComponentType.IMAGE,
//       value: "https://example.com/image1.jpg",
//     },
//   ],
//   id: "1",
// };

// const dummyRearrangeQuestionss: IRearrangeQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Rearrange the following items in correct order:",
//     },
//   ],
//   components: [
//     {
//       key: 1,
//       type: MatchComponentType.TEXT,
//       value: "First item",
//     },
//     {
//       key: 2,
//       type: MatchComponentType.TEXT,
//       value: "Second item",
//     },
//     {
//       key: 3,
//       type: MatchComponentType.TEXT,
//       value: "Third item",
//     },
//     {
//       key: 4,
//       type: MatchComponentType.IMAGE,
//       value: "https://example.com/image1.jpg",
//     },
//   ],
//   id: "2",
// };

// const AllModals = () => {
//   const [questions, setQuestions] = useState<IRearrangeQuestion[]>([dummyRearrangeQuestion, dummyRearrangeQuestionss]); // State for questions
//   const [editing, setEditing] = useState(false);
//   const [editingQuestion, setEditingQuestion] = useState<IRearrangeQuestion | undefined>();
//   const [uploadAsset] = useUploadImageMutation();

//   const handleEdit = (question: IRearrangeQuestion) => {
//     handleSave(question);
//     setEditing(true);
//     setEditingQuestion(question);
//   };

//   const handleSave = (updatedQuestion: IRearrangeQuestion) => {
//     console.log("Saving updated question:", updatedQuestion); // Log the updated question
//     setQuestions((prev) =>
//       prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
//     ); // Update questions state
//     setEditing(false);
//   };

//   const handleCloseEditor = () => {
//     setEditing(false);
//   };

//   const uploadImage = async (formData: FormData) => {
//     const result: any = await uploadAsset({
//       formData,
//       prefix: "rearrange-image",
//     }).unwrap();
//     return result.url;
//   };
//   const uploadAudio = async (formData: FormData) => {
//     const result = await uploadAsset({
//       formData,
//       prefix: "audio",
//     }).unwrap();
//     return result.url;
//   };

//   return (
//     <div className="flex flex-col items-start gap-2 ml-4 mt-4">
//       <CompetitionForm />
//       <ProfessionCompetitionForm />
//       <NotifyUsersDialog />
//       <AcademicWorksheetForm />

//       {editing ? (
//         <RearrangeEditor
//           uploadImage={uploadImage}
//           uploadAudio={uploadAudio}
//           onSave={handleSave}
//           data={editingQuestion} // Start with empty data for new questions
//         />
//       ) : (
//         questions.map((question, index) => (
//           <RearrangeViewer
//             key={index}
//             question={question}
//             onEdit={() => handleEdit(question)}
//           />
//         ))
//       )}
//     </div>
//   );
// };

// export default AllModals;















// import React, { useState } from "react";
// import CompetitionForm from "./CompetitionForm";
// import ProfessionCompetitionForm from "./ProfessionCompetition";
// import NotifyUsersDialog from "./NotifyUsersDialog";
// import AcademicWorksheetForm from "./AcademicWorksheetForm";
// import { IMtfQuestion, MatchComponentType } from "../components/dash-components/Mtf/types";
// import { MtfEditor } from "../components/dash-components/Mtf/MtfEditor";
// import MatchTheFollowingViewer from "../components/dash-components/Mtf/MtfViewer";
// import { IRearrangeQuestion, } from "../components/dash-components/Rearrange/types"; // Import Rearrange types
// import {RearrangeEditor} from "../components/dash-components/Rearrange/RearrangeEditor"; // Import RearrangeEditor
// import RearrangeViewer from "../components/dash-components/Rearrange/RearrangeViewer"; // Import RearrangeViewer
// import { useUploadImageMutation } from "../app/api/apiSlice";

// // Dummy data for MatchTheFollowing question
// const dummyMatchQuestion: IMtfQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Match the following:",
//     },
//   ],
//   pairs: [
//     {
//       question: {
//         type: MatchComponentType.TEXT,
//         value: "Apple",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Fruit",
//       },
//     },
//     {
//       question: {
//         type: MatchComponentType.IMAGE,
//         value: "https://example.com/image1.jpg",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Image 1 Answer",
//       },
//     },
//   ],
//   id: "1",
// };

// // Dummy data for Rearrange question
// const dummyRearrangeQuestion: IRearrangeQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Rearrange the steps:",
//     },
//   ],
//   components: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Step 1",
//       key: 1,
//     },
//     {
//       type: MatchComponentType.IMAGE,
//       value: "https://example.com/step2.jpg",
//       key: 2,
//     },
//     {
//       type: MatchComponentType.TEXT,
//       value: "Step 3",
//       key: 3,
//     },
//   ],
//   id: "2",
// };

// const dummyRearrangeQuestion2: IRearrangeQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Rearrange steps:",
//     },
//   ],
//   components: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "yes",
//       key: 1,
//     },
//     {
//       type: MatchComponentType.IMAGE,
//       value: "https://example.com/step2.jpg",
//       key: 2,
//     },
//     {
//       type: MatchComponentType.TEXT,
//       value: "Step 3",
//       key: 3,
//     },
//   ],
//   id: "3",
// };


// const AllModals = () => {
//   const [mtfQuestions, setMtfQuestions] = useState<IMtfQuestion[]>([dummyMatchQuestion]); // State for MTF questions
//   const [rearrangeQuestions, setRearrangeQuestions] = useState<IRearrangeQuestion[]>([dummyRearrangeQuestion, dummyRearrangeQuestion2]); // State for Rearrange questions
//   const [editingMtf, setEditingMtf] = useState(false);
//   const [editingRearrange, setEditingRearrange] = useState(false);
//   const [uploadAsset] = useUploadImageMutation();

//   const handleEditMtf = (question: IMtfQuestion) => {
//     handleSaveMtf(question);
//     setEditingMtf(true);
//   };

//   const handleSaveMtf = (updatedQuestion: IMtfQuestion) => {
//     setMtfQuestions((prev) =>
//       prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
//     );
//     setEditingMtf(false);
//   };

//   const handleEditRearrange = (question: IRearrangeQuestion) => {
//     handleSaveRearrange(question);
//     setEditingRearrange(true);
//   };

//   const handleSaveRearrange = (updatedQuestion: IRearrangeQuestion) => {
//     setRearrangeQuestions((prev) =>
//       prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
//     );
//     setEditingRearrange(false);
//   };

//   const handleCloseEditor = () => {
//     setEditingMtf(false);
//     setEditingRearrange(false);
//   };

//   const uploadAudio = async (formData: FormData) => {
//     const result = await uploadAsset({
//       formData,
//       prefix: "audio",
//     }).unwrap();
//     return result.url;
//   };

//   const uploadImage = async (formData: FormData) => {
//     const result = await uploadAsset({
//       formData,
//       prefix: "image",
//     }).unwrap();
//     return result.url;
//   };

//   return (
//     <div className="flex flex-col items-start gap-2 ml-4 mt-4">
//       <CompetitionForm />
//       <ProfessionCompetitionForm />
//       <NotifyUsersDialog />
//       <AcademicWorksheetForm />

//       {editingMtf ? (
//         <MtfEditor
//           uploadImage={uploadImage}
//           uploadAudio={uploadAudio}
//           onSave={handleSaveMtf}
//           data={mtfQuestions[0]} // Use the first MTF question for editing
//         />
//       ) : (
//         mtfQuestions.map((question, index) => (
//           <MatchTheFollowingViewer
//             key={index}
//             question={question}
//             onEdit={() => handleEditMtf(question)}
//           />
//         ))
//       )}

//       {editingRearrange ? (
//         <RearrangeEditor
//           uploadImage={uploadImage}
//           uploadAudio={uploadAudio}
//           onSave={handleSaveRearrange}
//           data={rearrangeQuestions[0]} // Use the first Rearrange question for editing
//         />
//       ) : (
//         rearrangeQuestions.map((question, index) => (
//           <RearrangeViewer
//             key={index}
//             question={question}
//             onEdit={() => handleEditRearrange(question)}
//           />
//         ))
//       )}
//     </div>
//   );
// };

// export default AllModals;
