import { apiSlice } from "../../app/api/apiSlice";

const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getLatestActivities: builder.query<any, void>({
    //     query: () => "/activities",
    // }),
    getLatestActivities: builder.query<
      any,
      { limit?: number; date?: string; competitionId?: string; grade?: number }
    >({
      query: ({ limit, date, competitionId, grade }) => {
        // Construct query parameters based on optional inputs
        if (grade) {
          return `/activities?&date=${date}&competitionId=${competitionId}&grade=${grade}`;
        } else {
          return `/activities?&date=${date}&competitionId=${competitionId}`;
        }
      },
    }),

    notifyUsers: builder.mutation({
      query: (activityId) => ({
        url: `/activity/${activityId}/notifyUsers`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetLatestActivitiesQuery, useNotifyUsersMutation } =
  schoolsApiSlice;
