import React, { useState, useEffect, useRef } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { result, uniqueId } from "lodash";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import CaseReviewer, {
  CaseData,
} from "../../components/dash-components/CaseReviewer";
import { useCreateCaseMutation, useUpdateCaseMutation } from "./CasesApiSlice";
import { transformCaseData } from "../../quiz/parsingJson/transformCaseData";

const ImportStatements: React.FC = () => {
  const [cases, setCases] = useState<CaseData[]>([]);
  const [activeCaseIndex, setActiveCaseIndex] = useState<number>(0);

  const [createStatement, { data, error, isLoading }] = useCreateCaseMutation();
  const [updateCase] = useUpdateCaseMutation();

  
  const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status } = info.file;

    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === "string") {
          const parsedResult = JSON.parse(e.target.result);
          const parsedResultArray = Array.isArray(parsedResult)
            ? parsedResult
            : [parsedResult];

          // Transform the parsed result into the desired format
          const transformedData = transformCaseData(parsedResultArray);
          console.log("transformedData", transformedData);

          const cases: CaseData[] = transformedData;

          // Add unique IDs to questions within each case
          const updatedCases = cases.map((c) => {
            return {
              ...c,
              questions: c.questions?.map((q) => {
                return {
                  ...q,
                  id: uniqueId(), // Assign a unique ID to each question
                };
              }),
            };
          });

          // Validate that each case has an externalId
          updatedCases.forEach((c) => {
            if (!c.externalId) {
              throw new Error("External ID is required");
            }
          });

          // Update the state with the new transformed cases
          setCases([...updatedCases]);
        }
      };

      if (info.file.originFileObj) {
        reader.readAsText(info.file.originFileObj);
      }
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handlePrevious = () => {
    setActiveCaseIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setActiveCaseIndex((prev) => (prev < cases.length - 1 ? prev + 1 : prev));
  };


  const saveQuestionToServer = async (caseData: CaseData) => {
    try {
      // Transform en_question and en_options to question and options, and remove the original fields
      const transformedQuestions = caseData.questions?.map(
        ({ id, allConceptTags, concept,gems, ...rest }) => ({
          ...rest, // Keep the other fields
          // question: en_question, // Rename en_question to question
          // options: en_options, // Rename en_options to options
        })
      );

      // Update caseData with transformed questions
      const gradeNumber = parseInt(caseData.grade?.replace("Class - ", "") || "0", 10); 

      const updatedCaseDataForApi:any = {
        ...caseData,
        grade: gradeNumber,
        statements:caseData.statement,
        questions: transformedQuestions,
      };
      
      let result: { id: string };
      if ('statement' in updatedCaseDataForApi) {  
        delete updatedCaseDataForApi.statement;
      }

      if (updatedCaseDataForApi.id) {
        console.log("Updating case", updatedCaseDataForApi);
        result = await updateCase(updatedCaseDataForApi).unwrap();
      } else {
        console.log("Creating case", updatedCaseDataForApi);
        result = await createStatement(updatedCaseDataForApi).unwrap();
      }


      // setCases((prevCases) => {
      //   const updatedCases = [...prevCases];
      //   updatedCases[activeCaseIndex] = {
      //     ...updatedCases[activeCaseIndex],
      //     ...caseData,
      //     id: result!.id,
      //   };
      //   return updatedCases;
      // });

      message.success("Content saved successfully");
    } catch (error: FetchBaseQueryError | any) {
      if (error.status === 400) {
        message.error(
          "Invalid content. Please check the content and try again"
        );
      } else if (error.status === 409) {
        message.error("External ID conflict. This case has been saved already");
      }
    }
  };

  if (!cases || !cases.length) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Upload
          accept=".json"
          showUploadList={false}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess?.("ok" as any);
            }, 0);
          }}
          onChange={handleFileUpload}
        >
          <Button type="primary" size="large" icon={<UploadOutlined />}>
            Upload File
          </Button>
        </Upload>
      </div>
    );
  }

  return (
    <CaseReviewer
      title={
        cases.length > 1
          ? `Case ${activeCaseIndex + 1} of ${cases.length}`
          : "Case"
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
      data={cases[activeCaseIndex]}
      onOk={saveQuestionToServer}
      showMetadata
      activeCaseIndex={activeCaseIndex}
    />
  );
};

export default ImportStatements;












// const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
  //   const { status } = info.file;
  //   if (status === "done") {
  //     const reader = new FileReader();
  //     reader.onload = (e: ProgressEvent<FileReader>) => {
  //       if (e.target && typeof e.target.result === "string") {
  //         const parsedResult = JSON.parse(e.target.result);

  //         const transformedData = transformCaseData(parsedResult);
  //         console.log("transformedData", transformedData);
  //         const cases: CaseData[] = Array.isArray(parsedResult)
  //           ? parsedResult
  //           : [parsedResult];

  //         cases.forEach((c) => {
  //           if (!c.externalId) {
  //             throw new Error("External ID is required");
  //           }
  //         });

  //         // add key to each question
  //         cases.forEach((c) => {
  //           c.questions = c.questions.map((q) => ({ ...q, key: uniqueId() }));
  //         });
  //         setCases([...cases]);
  //       }
  //     };
  //     if (info.file.originFileObj) {
  //       reader.readAsText(info.file.originFileObj);
  //     }
  //   } else if (status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  // };

  // const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
  //   const { status } = info.file;
  //   if (status === "done") {
  //     const reader = new FileReader();
  //     reader.onload = (e: ProgressEvent<FileReader>) => {
  //       if (e.target && typeof e.target.result === "string") {
  //         const parsedResult = JSON.parse(e.target.result);
  //         Array.isArray(parsedResult) ? console.log("parsedResult", parsedResult) : console.log("parsedResult", parsedResult);

  //         // Transform the parsed result into the desired format
  //         const transformedData = transformCaseData(parsedResult);
  //         console.log("transformedData", transformedData);

  //         // transformedData.forEach((c) => {
  //         //   ...c,
  //         //   questions.forEach((q) => {
  //         //     ...q,
  //         //     id: uniqueId()
  //         //   })
  //         // }

  //         const cases: CaseData[] = Array.isArray(transformedData)
  //           ? transformedData
  //           : [transformedData];

  //         cases.map((c) => {
  //           return {
  //             ...c,
  //             questions: c.questions.map((q) => {
  //               return {
  //                 ...q,
  //                 id: uniqueId()
  //               }
  //             })
  //           }
  //         }

  //         // Validate that each case has an externalId
  //         cases.forEach((c) => {
  //           if (!c.externalId) {
  //             throw new Error("External ID is required");
  //           }
  //         });

  //         // Add a unique key to each question in the case
  //         cases.forEach((c) => {
  //           c.questions = c.questions.map((q) => ({ ...q, key: uniqueId() }));
  //         });

  //         // Update the state with the new transformed cases
  //         setCases([...cases]);
  //       }
  //     };
  //     if (info.file.originFileObj) {
  //       reader.readAsText(info.file.originFileObj);
  //     }
  //   } else if (status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  // };






  // const saveQuestionToServer = async (caseData: CaseData) => {
  //   try {
  //     let result: { id: string };

  //     if (caseData.id) {
  //       console.log("Updating case", caseData);
  //       result = await updateCase(caseData).unwrap();
  //     } else {
  //       console.log("Creating case", caseData);
  //       result = await createStatement(caseData).unwrap();
  //     }

  //     setCases((prevCases) => {
  //       const updatedCases = [...prevCases];
  //       updatedCases[activeCaseIndex] = {
  //         ...updatedCases[activeCaseIndex],
  //         ...caseData,
  //         id: result!.id,
  //       };
  //       return updatedCases;
  //     });

  //     message.success("Content saved successfully");
  //   } catch (error: FetchBaseQueryError | any) {
  //     if (error.status === 400) {
  //       message.error(
  //         "Invalid content. Please check the content and try again"
  //       );
  //     } else if (error.status === 409) {
  //       message.error("External ID conflict. This case has been saved already");
  //     }
  //   }
  // };