import { Button } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const SkillModule = () => {
    const navigate = useNavigate();
  const navigateToCreateSkillForm = () => {
    navigate("createSkill");
  };
  const navigateToShowSkillModule = () => {
    navigate("showSkillsModule");
  };
  return (
      <div className='p-4 '>
          
      <Button onClick={navigateToCreateSkillForm}>Create Skill Module</Button>
      <div className='mb-4'></div>
      <Button onClick={navigateToShowSkillModule}>Show Skill Module</Button>
    </div>
  )
}

export default SkillModule