// import React, { useState, useEffect } from "react";
// import { Button, Drawer, message, Space } from "antd";
// import DOMPurify from "dompurify";
// import styled from "styled-components";
// import HtmlEditorWithPreview from "../../../components/sectionEditor/HtmlEditorWithPreview";
// import { set } from "lodash";
// import { MathJax, MathJaxContext } from "better-react-mathjax";

// interface StatementEditorProps {
//   initialData: string[];
//   onSave: (content: string) => Promise<void>;
// }

// const ButtonContainer = styled.div`
//   position: absolute;
//   top: 2px;
//   right: 24px;
// `;

// const ContentDisplay = styled.div`
//   padding: 24px;
//   padding-top: 72px;
//   margin-bottom: 24px;
//   background-color: white;
//   height: 100%;
//   overflow: auto;
//   img {
//     max-width: 100%;
//     max-height: 200px; /* Set your desired maximum height */
//     width: auto;
//     height: auto;
//     display: block;
//     margin: 0 auto; /* Center the image */
//   }
// `;

// const StatementEditor: React.FC<StatementEditorProps> = ({
//   initialData,
//   onSave,
// }) => {
//   const [decodedData, setDecodedData] = useState<string[]>([]);
//   const [editedData, setEditedData] = useState<string[]>([]);
//   const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
//   const [isSaving, setIsSaving] = useState<boolean>(false);

//   useEffect(() => {
//     try {
//       const strippedHtml = initialData.map((item) => {
//         const decoded = decodeURIComponent(item);
//         const strippedHtml = stripStylingTags(decoded);
//         return strippedHtml;
//       });
//       setDecodedData(strippedHtml);
//       setEditedData(strippedHtml);
//     } catch (error) {
//       message.error("Failed to decode content");
//       setDecodedData([]);
//     }
//   }, [initialData]);

//   const stripStylingTags = (html: string): string => {
//     const doc = new DOMParser().parseFromString(html, "text/html");
//     doc.querySelectorAll("*").forEach((el) => {
//       el.removeAttribute("style");
//     });
//     const tagsToRemove = ["style", "font"];
//     tagsToRemove.forEach((tag) => {
//       doc.querySelectorAll(tag).forEach((el) => {
//         el.outerHTML = el.innerHTML;
//       });
//     });
//     return doc.body.innerHTML;
//   };

//   const handleEditorChange = (content: string) => {
//     setEditedData([...editedData, content]);
//   };

//   const handleSave = async () => {
//     try {
//       const arrayOfSanitizedContent = editedData.map(async (item) => {
//         const sanitizedContent = DOMPurify.sanitize(item);

//         setIsSaving(true);

//         await onSave(sanitizedContent);
//         message.success("Content saved successfully");
//         setIsDrawerVisible(false);
//       });
//       // setDecodedData(arrayOfSanitizedContent);
//     } catch (error) {
//       message.error("Failed to save content");
//     } finally {
//       setIsSaving(false);
//     }
//   };

//   return (
//     <MathJaxContext>
//       <div style={{ height: "100%", position: "relative" }}>
//         {decodedData?.map((item) => (
//           <div>
//             <ContentDisplay
//               dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}
//             />
//             <ButtonContainer>
//               <Button
//                 type="primary"
//                 onClick={() => setIsDrawerVisible(true)}
//                 size="large"
//               >
//                 Edit Content
//               </Button>
//             </ButtonContainer>
//           </div>
//         ))}
//         {editedData?.map((item) => (
//           <Drawer
//             title={<div>Edit Content</div>}
//             placement="right"
//             width="80%"
//             onClose={() => setIsDrawerVisible(false)}
//             open={isDrawerVisible}
//             extra={
//               <Space>
//                 <Button onClick={() => setIsDrawerVisible(false)}>
//                   Cancel
//                 </Button>
//                 <Button type="primary" onClick={handleSave} loading={isSaving}>
//                   Save
//                 </Button>
//               </Space>
//             }
//             style={{ padding: "24px" }}
//           >
//             <HtmlEditorWithPreview
//               html={item}
//               onHtmlChange={handleEditorChange}
//             />
//           </Drawer>
//         ))}
//       </div>
//     </MathJaxContext>
//   );
// };

// export default StatementEditor;












import React, { useState, useEffect } from "react";
import { Button, Drawer, message, Space } from "antd";
import DOMPurify from "dompurify";
import styled from "styled-components";
import HtmlEditorWithPreview from "../../../components/sectionEditor/HtmlEditorWithPreview";
import { MathJax, MathJaxContext } from "better-react-mathjax";

interface StatementEditorProps {
  initialData: string[];
  onSave: (content: string) => Promise<void>;
}

const ButtonContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 24px;
`;

const ContentDisplay = styled.div`
  padding: 24px;
  padding-top: 72px;
  margin-bottom: 24px;
  background-color: white;
  height: 100%;
  overflow: auto;
  img {
    max-width: 100%;
    max-height: 200px; /* Set your desired maximum height */
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image */
  }
`;

const StatementEditor: React.FC<StatementEditorProps> = ({
  initialData,
  onSave,
}) => {
  const [decodedData, setDecodedData] = useState<string[]>([]);
  const [currentEditIndex, setCurrentEditIndex] = useState<number | null>(null);
  const [editedContent, setEditedContent] = useState<string>("");
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    try {
      const strippedHtml = initialData.map((item) => {
        const decoded = decodeURIComponent(item);
        const strippedHtml = stripStylingTags(decoded);
        return strippedHtml;
      });
      setDecodedData(strippedHtml);
    } catch (error) {
      message.error("Failed to decode content");
      setDecodedData([]);
    }
  }, [initialData]);

  const stripStylingTags = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    doc.querySelectorAll("*").forEach((el) => {
      el.removeAttribute("style");
    });
    const tagsToRemove = ["style", "font"];
    tagsToRemove.forEach((tag) => {
      doc.querySelectorAll(tag).forEach((el) => {
        el.outerHTML = el.innerHTML;
      });
    });
    return doc.body.innerHTML;
  };

  const handleEditorChange = (content: string) => {
    setEditedContent(content);
  };

  const handleEditClick = (index: number) => {
    setCurrentEditIndex(index);
    setEditedContent(decodedData[index]);
    setIsDrawerVisible(true);
  };

  const handleSave = async () => {
    try {
      const sanitizedContent = DOMPurify.sanitize(editedContent);
      const updatedData = [...decodedData];
      updatedData[currentEditIndex!] = sanitizedContent;

      setIsSaving(true);

      await onSave(sanitizedContent);
      message.success("Content saved successfully");
      setDecodedData(updatedData);
      setIsDrawerVisible(false);
      setCurrentEditIndex(null);
    } catch (error) {
      message.error("Failed to save content");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <MathJaxContext>
      <div style={{ height: "100%", position: "relative" }}>
        {decodedData.map((item, index) => (
          <div key={index}>
            <ContentDisplay
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}
            />
            <ButtonContainer>
              <Button
                type="primary"
                onClick={() => handleEditClick(index)}
                size="large"
              >
                Edit Content
              </Button>
            </ButtonContainer>
          </div>
        ))}
        <Drawer
          title={<div>Edit Content</div>}
          placement="right"
          width="80%"
          onClose={() => setIsDrawerVisible(false)}
          open={isDrawerVisible}
          extra={
            <Space>
              <Button onClick={() => setIsDrawerVisible(false)}>Cancel</Button>
              <Button type="primary" onClick={handleSave} loading={isSaving}>
                Save
              </Button>
            </Space>
          }
          style={{ padding: "24px" }}
        >
          <HtmlEditorWithPreview
            html={editedContent}
            onHtmlChange={handleEditorChange}
          />
        </Drawer>
      </div>
    </MathJaxContext>
  );
};

export default StatementEditor;
